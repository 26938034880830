<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 30em !important;">
                <img src="/images/switch_company_illus.svg">
                <div class="mt-5">
                    <div class="mb-4 fw-600" style="font-size: 18px;color: #303031;">Success!</div>
                    <div>
                        You have Successfully Switched your company
                    </div>
                </div>
                <div class="d-flex aligin-items-center justify-content-center mt-6">        
                    <button
                        type="button"
                        class="btn btn-new-primary btn-smm"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="hideSelf"
                    >OK</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name", "success_message"],
    methods: {
        hideSelf() {
            this.$modal.hide(this.modal_name);
            this.$emit('hideSuccess');
        }
    }
}
</script>